<script setup>
import { usePrecognitiveForm } from "laravel-precognition-vue"

import { Head, useForm } from "@inertiajs/vue3"

import GuestLayout from "@/Layouts/GuestLayout.vue"

import Checkbox from "@/Components/Checkbox.vue"
import InputError from "@/Components/InputError.vue"
import InputLabel from "@/Components/InputLabel.vue"
import Link from "@/Components/Link.vue"
import TextInput from "@/Components/TextInput.vue"
import VButton from "@/Components/VButton/VButton.vue"

defineProps({
  canResetPassword: Boolean,
  status: String,
})

const form = usePrecognitiveForm(
  "post",
  route("login"),
  useForm({
    email: null,
    password: null,
    remember: false,
    dont_forget_me: false,
  }),
)

const submit = () => {
  form.submit({
    onFinish: () => form.reset("password"),
  })
}
</script>

<template>
  <GuestLayout>
    <Head title="Log in" />

    <div
      v-if="status"
      class="mb-4 font-medium text-sm bg-success-50 mt-4 py-4 px-4 text-success-800 rounded shadow"
    >
      {{ status }}
    </div>

    <div class="flex justify-between items-center">
      <div class="text-xl font-medium leading-7">
        {{
          $formatMessage({
            defaultMessage: "Sign In", // TODO Should be "Sign in"
            id: "LCQHnEc-",
            description: "auth.login",
          })
        }}
      </div>
      <div v-if="route().has('register')" class="text-sm leading-5 font-medium">
        or
        <Link :href="route('register')">{{
          $formatMessage({
            defaultMessage: "Create an Account",
            id: "xyTMOsj0", // TODO Should be "or <>create an account<>"
            description: "auth.login",
          })
        }}</Link>
      </div>
    </div>

    <form @submit.prevent="submit" class="mt-2">
      <div>
        <InputLabel for="email">{{
          $formatMessage({
            defaultMessage: "Email",
            id: "fg_EhNFo", // TODO should be "Email address"
            description: "auth.login",
          })
        }}</InputLabel>
        <TextInput
          id="email"
          type="email"
          class="mt-1 block w-full"
          @change="form.validate('email')"
          v-model="form.email"
          required
          autofocus
          autocomplete="username"
          placeholder="me@example.com"
          :class="{
            'border-failure-500 text-failure-700 border-2': form.errors.email,
          }"
        />
        <InputError class="mt-2" :message="form.errors.email" />
      </div>

      <div class="mt-4">
        <InputLabel for="password">{{
          $formatMessage({
            defaultMessage: "Password",
            id: "01mTSQOL",
            description: "auth.login",
          })
        }}</InputLabel>
        <TextInput
          id="password"
          type="password"
          class="mt-1 block w-full"
          :class="{
            'border-failure-500 text-failure-700 border-2':
              form.errors.password,
          }"
          @change="form.validate('password')"
          v-model="form.password"
          required
          autocomplete="current-password"
        />
        <InputError class="mt-2" :message="form.errors.password" />
      </div>

      <div class="block mt-4 flex items-center justify-between">
        <label class="flex items-center">
          <Checkbox name="remember" v-model:checked="form.remember" />
          <span class="ml-2 text-sm text-gray-700">{{
            $formatMessage({
              defaultMessage: "Remember",
              id: "vXoncsR_", // TODO Should be "Remember me"
              description: "auth.login",
            })
          }}</span>
        </label>
        <Link
          v-if="canResetPassword"
          :href="route('password.request')"
          class="text-sm text-cerulean-600 hover:text-cerulean-900"
        >
          {{
            $formatMessage({
              defaultMessage: "Forgot password?",
              id: "_WJSwr0y",
              description: "auth.login",
            })
          }}
        </Link>
      </div>

      <div class="mt-4">
        <VButton
          primary
          class="w-full"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          {{
            $formatMessage({
              defaultMessage: "Sign In",
              id: "LCQHnEc-", // TODO Should be "Sign in"
              description: "auth.login",
            })
          }}
        </VButton>
      </div>
    </form>
  </GuestLayout>
</template>
